import React, { useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import About from './About';
import '../style/main.css';

function Header() {

    // function active Tab
    const location = useLocation();
    
    // function hamburger navigation
    const navRef = useRef();
    const showNavbar = () => {
        navRef.current.classList.toggle('responsive_nav');
    }

    return (
        <header>
            {/* link Home Title */}
            <Link to={'/'} className="link">
                <h3 className='headTitle'>Guy Stevenson</h3> 
            </Link>
            
            <nav ref={navRef} id='navigationLink' >
                
                <a href='/about' className={location.pathname == "/about" ? "active-link" : ""} >
                About</a>
                <a href='/books' className={location.pathname == "/books" ? "active-link" : ""} >
                    Books</a>
                <a href='/reviews' className={location.pathname == "/reviews" ? "active-link" : ""} >
                    Reviews</a>
                <a href='/essays' className={location.pathname == "/essays" ? "active-link" : ""} >
                    Essays</a>
                <a href='/music' className={location.pathname == "/music" ? "active-link" : ""} >
                    Music</a>
                <a href='/contact' className={location.pathname == "/contact" ? "active-link" : ""} >
                    Contact</a>
                <button className='nav-btn nav-close-btn' onClick={showNavbar}>
                    <FaTimes />
                </button>
            </nav>
            <button className='nav-btn nav-close-btn' onClick={showNavbar}>
                <FaBars />
            </button>
        </header>
    );
}

export default Header;