import React, { useRef } from "react";
import emailjs from '@emailjs/browser';
import Swal from "sweetalert2";
import '../style/contact.css';

function Contact() {

  const form = useRef();
// form EmailJs
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_lgepxam', 'template_02tuhfh', e.target, 'SYsVj1UHj0kmlnt_V')
      .then((result) => {
          console.log(result.text);
// alert msg sent
          Swal.getIcon();
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
    
          Toast.fire({
            icon: 'success',
            title: 'Your message has been sent !'
          })
// END alert msg sent
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  };

    return(
        <>
        <div className="contactDiv">
            <form onSubmit={sendEmail} >
                <label for="name">Name</label><br />
                <input type="text" name="name" size="100" /><br />

                <label for="email">Email</label><br />
                <input type="email" name="email" size="100" /><br />

                <label for="subject">Subject</label><br />
                <input type="text" name="subject" size="100" /><br />

                <label for="text">Message</label><br />
                <textarea type="text" name="message" rows="4" cols="100" placeholder="I would like to contact you..."></textarea><br />

                <input  className="button" type="submit" value={"Submit"}/>
            </form>
        </div>
        </>
    );
}

export default Contact;