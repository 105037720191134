import React from 'react';
// bootstrap and style
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
// Pages and Structures
import Header from './component/Header';
import About from './component/About';
import Footer from './component/Footer';
import Home from './component/Home';
import Books from './component/Books';
import Reviews from './component/Reviews';
import Essays from './component/Essays';
import Music from './component/Music';
import Contact from './component/Contact';
// Router-dom 
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    
    <React.Fragment>
      
      <Header />
      <div className='container'>
        <Routes>
          <Route exact path='/' element={ <Home /> } />
          <Route exact path='/about' element={ <About /> } />
          <Route exact path='/books' element={ <Books /> } />
          <Route exact path='/music' element={ <Music /> } />
          <Route exact path='/reviews' element={ <Reviews /> } />
          <Route exact path='/essays' element={ <Essays /> } />
          <Route exact path='/contact' element={ <Contact /> } />
        </Routes>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default App;
