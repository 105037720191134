import React from "react";
import '../style/footer.css';
import { FaInstagram, FaTwitter, FaLongArrowAltUp } from 'react-icons/fa';

function Footer() {
    return(
        <footer>
            <div className='footerTop'>
                <div className="icon">
                    <a href="https://www.instagram.com/visitations_" target="_blank">
                        <FaInstagram />
                    </a>
                </div>
                <hr />
                <div className="icon">
                    <a href="https://twitter.com/gnastevenson" target="_blank">
                        <FaTwitter />
                    </a>
                </div>
            </div>
            
            <div className='footerBottom'>
                <div>
                    <p className='copyright'>© Guy Stevenson - 2022</p>
                </div>
                <div className='arrow'>
                    <a href="#" target="_top">
                        <FaLongArrowAltUp />
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;