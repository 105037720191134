import React from "react";
import { Link } from 'react-router-dom';
import '../style/music.css';

function Music() {
    return(
        <>
        <div id="musicDiv">
            <div id="music">
                <span>
                    <img src={require('../images/visitations.png')} width="250px" />
                </span>
                <a href="https://visitations-iiiiii.bandcamp.com/" target="_blank">
                    <div className="music"></div>
                </a>
            </div>
        </div>
        </>
    );
}

export default Music;