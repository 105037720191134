import React from "react";
import { useState } from "react";
import '../style/essays.css';
import EssaysData from '../data/essays.json'

function Essays() {
    
    
    
    EssaysData.sort(function(a,b){
        if(a>b) return 1;
        if(a<b) return -1;
        return 0;
    });
    console.log(EssaysData);
    return(
        <>
        <div id="divEssays">
                <div id="essaysBorder">
                    
                    {
                        EssaysData.sort((a,b)=> a.id > b.id ? -1: 1).map((essaysDetail, index)=>{
                            
                            return(
                        <div className="essaysText" key={essaysDetail.id}>
                            <div className="essaysLink">
                                <div className="essays-one">
                                    <a href={essaysDetail.link} target="_blank" >
                                        <span className="pdf-text">{essaysDetail.title}</span>
                                    </a>
                                </div>
                                <div>
                                    <span>{essaysDetail.source}</span>
                                </div>
                            </div>
                        </div>
                            )
                        })
                    }

                </div>
            </div>
        </>
    );
}

export default Essays;