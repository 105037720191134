import React from "react";
import '../style/home.css';
import { Link } from 'react-router-dom';

function Home() {
    return(
        <>
        <div id="portrait">
            <div id="divPortrait">
            </div>
        
            <div id="homeText">
                <div>
                    <p>
                    Guy Stevenson is an author and academic, specialising in the history of the 1960s Counterculture. 
                    </p>
                    <p>
                    He teaches at Goldsmiths and Queen Mary universities, and reviews for the Times Literary Supplement, 
                    the Literary Review and the LA Review of Books.
                    </p>
                </div>
                <a href="/about"> <div id="square"> </div></a>
            </div>
        </div>
        </>
    );
}

export default Home;