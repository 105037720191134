import React, { useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../style/books.css';
import { Card } from "react-bootstrap";
import BooksData from '../data/books.json';
import ReviewsData from '../data/bookReviews.json';

function Books() {

    return(
        <>
        <div id='bookDivW'>
            {BooksData.sort((a,b)=> a.id > b.id ? -1: 1).map((bookFile, index)=>{
                return( 
                <>
                <div className="allBooksWriting" key={bookFile.id}>
                <div className="imageAndLegend">
                    <div>
                        <legend>{bookFile.title}</legend>
                    </div>
                    
                    <div className='booksImagesW'>
                        <div>
                            <a href={bookFile.link2}
                            target='_blank' >
                                <img src={bookFile.frontImg} alt={bookFile.altImg} 
                                 className="firstBookImg" />
                            </a>
                        </div>
                
                        <div>
                            <a href={bookFile.link2}
                            target='_blank'>
                                <img src={bookFile.backImg}  alt={bookFile.altImg}
                                  className="firstBookImg" />
                            </a>
                        </div>
                    </div>
                </div>
                
                <div className="divBuyBookBtn">
                    <a href={bookFile.link}
                        target='_blank' className="buyBookBtn">
                        {bookFile.btnTxt}
                    </a>
                </div>
            </div>
                <div id="see-review" >
                <Accordion defaultActiveKey="0" className="see-review">
                    <Accordion.Item eventKey="1" className="see-review-item">
                        <Accordion.Header className="see-review-header">See Reviews</Accordion.Header>
                            <Accordion.Body>
                                {ReviewsData.sort((a,b)=> a.id > b.id ? -1: 1).map((review, index)=>{
                                    return(
                                <Card className="text-review">
                                    <Card.Body>
                                        <a href={review.link} target="_blank" className={review.class}>
                                            {review.text}<br/>
                                            <span className="author-reviews">
                                            {review.author}
                                            </span>
                                        </a>
                                    </Card.Body>
                                </Card>
            )})}
                            </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                </div>
                </>
                )
            })}
            
        </div>
        </>
    );
}

export default Books;