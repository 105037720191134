import React from "react";
import '../style/about.css';
import { Link } from 'react-router-dom';


function About() {
    return (
        <>
        <div id="aboutText">
        <h1>Guy Stevenson</h1> 
        <p>writes, teaches literature, and makes music – more or less in that order.</p>
        <div id='bookDiv'>
            <div id='bookText'>
            <p>His first book was about the Beat Generation <br/> (Anti-Humanism in the Counterculture, (Palgrave Macmillan)).</p>
            </div>

            <div className="portraitAbout">
            </div>
            
        </div>
        
        <p>His next is a history of the post-Trump Culture Wars.</p>
        <p>See <Link to="/reviews" className='bgLink'>Reviews</Link> for his work in The Times Literary Supplement, Literary Review, and 3AM. </p>
        <p>See <Link to="/essays" className='bgLink'>Essays</Link> for contributions to the Los Angeles Review of Books, Textual Practice,</p>
        <p>The European Journal of English Literature and more.</p>
        </div>
        </>
    );
}

export default About;