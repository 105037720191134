import React from "react";
import '../style/reviews.css';
import ReviewData from '../data/reviews.json'
import { FaFilePdf, FaFile } from 'react-icons/fa';

function Reviews() {

    return(
        <>
 
            <div id="divReview">
                <div id="reviewBorder">
{/* All the Data are on Json File */}
                    {ReviewData.sort((a,b)=> a.id > b.id ? -1: 1).map((reviewDetail, index)=>
                    // Function pdf 
                    {
                        // function download pdf
                        const FilePdf = () => {
                            // using Java Script method to get PDF file
                                fetch(reviewDetail.pdf.file).then(response => {
                                    response.blob().then(blob => {
                                        // Creating new object of PDF file
                                        const fileURL = window.URL.createObjectURL(blob);
                                        // Setting various property values
                                        let alink = document.createElement('a');
                                        alink.href = fileURL;
                                        alink.download = reviewDetail.pdf.title;
                                        alink.click();
                                    })
                                })
                        }
                    // Function style background image on small screen
                    const mediaScreen = {
                        background: `linear-gradient(to bottom, rgba(93, 93, 93, 0.52), rgba(26, 26, 26, 0.73)), url('${reviewDetail.img}')`,
                        backgroundRepeat: "no-repeat",
                        backgroundClip: "border-box",
                        backgroundAttachment: "local",
                        backgroundPosition: "center top 40%",
                        backgroundSize: "cover"
                    }
                    // Function Background Image 'onMouseOver'
                    function BackImage(e){
                        e.target.style.background = `linear-gradient(to bottom, rgba(93, 93, 93, 0.52), rgba(26, 26, 26, 0.73)), url('${reviewDetail.img}')`;
                        e.target.style.backgroundSize = "cover";
                        e.target.style.backgroundRepeat = "no-repeat";
                        e.target.style.backgroundClip = "border-box";
                        e.target.style.backgroundAttachment = "local";
                        e.target.style.backgroundPosition = "center top 25%";
                    }
                    // Function remove image 'onMouseOut'
                    function BackImageRemoved(e){
                        e.target.style.backgroundImage = "none";
                    }

                        return(
                    <>
                    <div className="reviewText" key={reviewDetail.id}>
                        {/* link + img */}
                            <div  className="small-screen-img" style={mediaScreen}>
                                {/* small screen img */}
                            </div>
                            <div className="reviewLink">
                                <a href={reviewDetail.link} target="_blank" >
                                    <div className="pdf-img" onMouseOver={BackImage} onMouseOut={BackImageRemoved} onClick={BackImageRemoved} >
                                        <span className="pdf-text">{reviewDetail.text}</span>
                                        <span>{reviewDetail.date}</span>
                                    </div>
                                </a>
                            </div>
                            {/* button pdf */}
                            <div className="pdf">

                                <button className="btn-pdf"  onClick={FilePdf} 
                                disabled= {reviewDetail.pdf.file == null ? true : false }>
                                    <FaFilePdf /> &nbsp; Download File 
                                </button>
                                
                            </div>
                    </div>
                    </>    
                    
                        )

                    })}

                </div>
            </div>
        
        </>
    );
}

export default Reviews;